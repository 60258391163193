class graphItem {
  constructor(
    title,
    description,
    imgPath,
    graphLink,
    commentary,
    imgFlip,
    sources,
    graphName,
    isLive
  ) {
    this.title = title;
    this.linkID = title.split(" ").join("_") + "_link";
    this.description = description;
    this.imgPath = imgPath;
    this.graphLink = graphLink;
    this.commentary = commentary;
    this.imgFlip = imgFlip === "flip";
    this.sources = sources;
    this.graphName = graphName;
    this.dataRequirements = dataRequirements[graphName];
    this.isLive = isLive === "isLive";
  }
}

export let graphCategories = [
  "tracking",
  "compromising",
  "profiling",
  "targeting",
  "socialising",
];
export let sources = [
  "facebook",
  "google",
  "twitter",
  "linkedin",
  "instagram",
  "spotify",
];
export let dataRequirements = {
  "Off-Facebook Activity": [
    "ads_and_businesses->your_off-facebook_activity.json",
  ],
  trackermap: ["security_and_login_information->account_activity.json"],
  activityplots: [
    "comments->comments.json",
    "security_and_login_information->account_activity.json",
    "likes_and_reactions->pages.json",
    "likes_and_reactions->posts_and_comments.json",
    "events->your_event_responses.json",
  ],
  watchTime: ["about_you->viewed.json"],
  FBmessageheatmap: [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  Mood: [
    "messages->inbox",
    "profile_information->profile_information.json",
    "StreamingHistory.json",
    "comments->post_comments.json",
    "content->posts_1.json",
    "tweet.js",

  ],
  personalitytraits: [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  "Needs and Values": [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  Demographics: ["profile_information->profile_information.json"],
  facebooksentiment: [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  broadinterests: ["ads_and_businesses->ads_interests.json"],
  Interests: ["ads_and_businesses->ads_interests.json"],
  adstable: [
    "about_you->viewed.json",
    "ads_and_businesses->advertisers_you've_interacted_with.json",
  ],
  advertisinglists: [
    "ads_and_businesses->advertisers_who_uploaded_a_contact_list_with_your_information.json",
  ],
  "Shopping Preferences": [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  "Activity Preferences": [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  topfriends: ["messages->inbox"],
  FBfriends: ["friends->friends.json"],
  FBreactions: ["likes_and_reactions->posts_and_comments.json"],
  messagebarchartrace: [
    "messages->inbox",
    "profile_information->profile_information.json",
  ],
  "Cybersecurity Summary": [
    "security_and_login_information->account_activity.json",
    "ads_and_businesses->your_off-facebook_activity.json",
    "ads_and_businesses->ads_interests.json",
    "likes_and_reactions->posts_and_comments.json",
  ],
  datataggeroverview: ["comments->comments.json"],
  datataggerdeepdive: ["comments->comments.json"],
  googletrackermap: ["Location History->Location History.json"],
  googleactivity: [
    "My Activity->Search->My Activity.html",
    "My Activity->YouTube->My Activity.html",
    "My Activity->Image Search->My Activity.html",
    "My Activity->Video Search->My Activity.html",
  ],
  googlesearch: [
    "My Activity->Search->My Activity.html",
    "Chrome->BrowserHistory.json",
  ],
  youtubesearch: ["My Activity->YouTube->My Activity.html"],
  youtubewatchtime: ["My Activity->YouTube->My Activity.html"],
  TweetStats: ["tweet.js"],
  Twitter_link_clicks: ["user-link-clicks.js"],
  Twitter_time_spent: ["ad-impressions.js"],
  twittertweets: ["tweet.js"],
  Twitter_Interests: ["personalization.js"],
  twitterads: ["ad-impressions.js"],
  Twitter_Audiences: ["personalization.js"],
  twitterinteractions: ["tweet.js"],
  twittertagger: ["tweet.js"],
  Instagram_Activity: [
    "followers_and_following->following.json",
    "followers_and_following->followers.json",
    "likes->liked_comments.json",
    "likes->liked_posts.json",
    "content->stories.json",
    "content->archived_posts.json",
    "comments->post_comments.json",
    "content->posts_1.json",
    "content->posts_2.json",
    "content->posts_3.json",
    "content->posts_4.json"
  ],
  Instagram_Following: ["followers_and_following->following.json", "followers_and_following->followers.json"],
  Instagram_Likes: ["likes->liked_comments.json", "likes->liked_posts.json"],
  Music: ["StreamingHistory.json"],
  linkedininferences: ["Inferences_about_you.csv"],
  linkedinconnections: ["Connections.csv"],
  breaches: [
    "account_information->personal_information.json",
    "profile.json",
    "profile_information->profile_information.json",
    "Email Addresses.csv",
    "account.js",
    "Chrome->Autofill.json",
    "Profile->Profile.json",
  ]
};



export let trackingGraphList = [
  new graphItem(
    "Off-Facebook Activity",
    "Hundreds of companies share data they collect about you with Facebook, meaning Facebook tracks what you do even when you are off their site!",
    "placeholders/offFb.webp",
    graphCategories?.[0] + "/off-fb-activity",
    [
      "An absurd number of companies share the data they collect about you with Facebook, meaning Facebook tracks what you do even when you are off their site!",
      "Holy moly this is scary! We're digging deeper into what they've shared to figure out how much your privacy has been breached.",
      "For now, just marvel at how Facebook has persuaded them all to give up your privacy, without you even knowing.",
      "And let us know any feedback/requests you have, or ways we can make this clearer or more useful!",
    ],
    "flip",
    ["facebook"],
    "Off-Facebook Activity",
    "notLive"
  ),
  new graphItem(
    "Your location",
    "Facebook has logged every IP address you've ever logged in from, and can geolocate them to figure out every city and country you've ever been to.",
    "placeholders/mapPreview.webp",
    graphCategories?.[0] + "/IPLocation",
    [
      "Facebook logs every IP address you've ever logged in from, and can geolocate them to figure out your rough location.",
      "This data gives away every city and country you've ever been to! Zoom into your hometown, or zoom out to see your global travels.",
      "Your location data is sensitive - it can be used to credibly impersonate you, your company, or a travel provider, especially if paired with other data.",
    ],
    "noflip",
    ["facebook", "google"],
    "trackermap",
    "notLive"
  ),
  new graphItem(
    "Facebook Activity",
    "Your activity on Facebook allows them to build up a picture of what your life is like, both online and offline.",
    "placeholders/fbactivityPreview.webp",
    graphCategories?.[0] + "/fb-activity",
    [
      "When and how you use Facebook, the videos you watch, and the events you attend all give Facebook clues about what your life is like, and how it has changed over time.",
      "Facebook has been focusing a lot on video content and event attendance, as both are very attention grabbing.",
      "Collecting data on what you watch is key to targeting you with more persuasive ads, and watching videos takes up hours of time - how much time have you wasted recently?",
    ],
    "noflip",
    ["facebook"],
    "activityplots",
    "notLive"
  ),
  new graphItem(
    "Tweet Activity",
    "Your tweet activity allows Twitter to build up a picture of what your life is like, both online and offline.",
    "placeholders/tweetActivityPreview.webp",
    graphCategories?.[0] + "/tweet-activity",
    [
      "When and how you use Twitter gives clues as to what your life is like, and how it has changed over time.",
      "This just shows various activities over time, we're digging to find deeper insights. We plan to show how much can be inferred from just a few key facts.",
      "Sneaky data collection by data brokers means it's even worse. Companies just identify you in their collections to get the rest they've collected!",
    ],
    "noflip",
    ["twitter"],
    "TweetStats",
    "isLive"
  ),
  new graphItem(
    "Twitter Clicks",
    "Twitter tracks every link you've ever clicked from their app. This allows them to figure out what sorts of content get you engaged enough to click!",
    "placeholders/twitterLinksClickedPreview.webp",
    graphCategories?.[0] + "/twitter-link-clicks",
    [
      "Twitter tracks every link you've ever clicked from their app. This allows them to figure out what sorts of content get you engaged enough to click!",
      "This helps them understand what things on the wider web you're interested in, beyond just the Twitterverse. Super helpful for ad-targeting!",
      "It's actually a super cool dataset, if you want, you can connect it into Supersurfer and have all these links categorized by interest!",
    ],
    "noflip",
    ["twitter"],
    "Twitter_link_clicks",
    "notLive"
  ),
  new graphItem(
    "Twitter time spent",
    "Twitter tracks how long you spend, every time you open their app. They use this to figure out what sorts of content keep you engaged for longer!",
    "placeholders/twitterTimeSpentPreview.webp",
    graphCategories?.[0] + "/twitter-time-spent",
    [
      "Twitter tracks how long you spend in app. Twitter use this metric to optimise for 'time-in-app' by figuring out what content keeps you engaged for longer.",
      "Tracking this helps them understand what things grab your attention. Your attention is the source of their profit, so they want as much of it as they can get!",
      "This is just one of the ways that Twitter's interests aren't aligned with yours.",
    ],
    "noflip",
    ["twitter"],
    "Twitter_time_spent",
    "notLive"
  ),
  new graphItem(
    "Youtube watch time",
    "Youtube (i.e. Google) tracks how long you spend, every time you open their app or site. They use this to figure out what videos keep you engaged for longer!",
    "placeholders/twitterTimeSpentPreview.webp",
    graphCategories?.[0] + "/youtube-watch-time",
    [
      "Google tracks what you watch on Youtube. This graph shows how much time you spend watching Youtube per day.",
      "Google use this to optimise for 'time-in-app' by figuring out what content keeps you engaged for longer.",
      "Tracking this helps them understand what things grab your attention. Your attention is the source of their profit, so they want as much of it as they can get!",
      "This is just one of the ways that Google's interests aren't aligned with yours.",
    ],
    "noflip",
    ["google"],
    "youtubewatchtime",
    "notLive"
  ),
  new graphItem(
    "Google Activity",
    "Google records every action you take on their services, which is a decent proxy for how active you are online - do you tend to unplug in the holidays?",
    "placeholders/googleactivityPreview.webp",
    graphCategories?.[0] + "/google-activity",
    [
      "Google tracks your actions across all its services; this includes Search but also YouTube, Gmail, Drive etc.",
      "Which means that if you searched, clicked, or viewed something, they know!",
      "Currently we have aggregated your activity from Search, YouTube, Images, and Videos to show you when you are most active.",
      "For each datapoint, Google collected exactly what the action was: they hold very granular data about you. It's also a proxy for how active you are online, especially if you use Google a lot.",
    ],
    "noflip",
    ["google"],
    "googleactivity",
    "notLive"
  ),
  new graphItem(
    "Websites Visited",
    "The websites you visit are a great proxy for what you're up to every minute of every day; explore your favorite websites over time!",
    "placeholders/websitesPreview.webp",
    graphCategories?.[0] + "/websites-visited",
    [
      "Those website visits reflect your daily activities, whether it's visiting social media, doing research for a project, or reading online news sources.",
      "The websites you visit help Google to target you better: from what sites you visit, terms you search, to the time you spend on each site, Google has one of the most rich datasets about you!",
      "Ultimately, this is how you pay for Google: the company makes billions through its advertising network because they know exactly which ads to show you when.",
      "If this trade-off doesn't make you happy, look into Google's privacy settings; or take it a step further and switch to privacy-preserving tools.",
    ],
    "noflip",
    ["google"],
    "googlesearch",
    "notLive"
  ),
  new graphItem(
    "Instagram Activity",
    "Your Instagram activity allows Facebook to build up a picture of what your life is like, both online and offline.",
    "placeholders/tweetActivityPreview.webp",
    graphCategories?.[0] + "/insta-activity",
    [
      "When and how you use Instagram gives clues as to what your life is like, and how it has changed over time.",
      "This just shows various activities over time, we're digging to find deeper insights. We plan to show how much can be inferred from just a few key facts.",
      "Sneaky data collection by data brokers means it's even worse. Companies just identify you in their collections to get the rest they've collected!",
    ],
    "noflip",
    ["instagram"],
    "Instagram_Activity",
    "notLive"
  ),
];

export let profilingGraphList = [
  new graphItem(
    "Mood",
    "Your activity on Facebook allows them to build up a picture of what your life is like, both online and offline.",
    "placeholders/moodPreview.webp",
    graphCategories[2] + "/mood",
    [
      "When you send a message, hidden in the words is a clue about how you felt in that moment. By analysing and aggregating those clues, you can track your mood over time since you started Facebook!",
      "Do you recognize good and bad days? This graph shows you the sentiment, in the messages and tweets you send and receive.",
      "Sentiment is derived from words and their context, as well as smileys, capitalization and punctuation. Although this is no exact science, it is a good proxy for how your mood evolved!",
      "For Spotify, we use the audio features of the song to figure out what mood you might have been in when listening to that song.",
    ],
    "noflip",
    ["facebook", "twitter", "spotify", "instagram"],
    "Mood",
    "isLive"
  ),
  // new graphItem(
  //   "Demographics",
  //   "Just from your demographics, or city, somebody can figure out a lot about your life. Every detail helps understand who you are.",
  //   "placeholders/demographicsPreview.webp",
  //   graphCategories[2] + "/demographics",
  //   [
  //     "Just from your demographics, or the city you live in, somebody can understand a lot about your life.",
  //     "We're planning on adding as much information as we can infer about you from these details as possible.",
  //     "What we want to show is that no information is truly 'basic', even just your address hints strongly at your income-level.",
  //   ],
  //   "noflip",
  //   ["facebook"],
  //   "Demographics",
  //   "notLive"
  // ),
  new graphItem(
    "Personality traits",
    "The data you've given Facebook includes thousands of words of text, which all give away little hints of your personality.",
    "placeholders/personalityPreview.webp",
    graphCategories[2] + "/traits",
    [
      "The thousands of words you've written on Facebook all give away hints of your personality. Be warned it's not perfect! But decisions get made about what to show you, based on analysis like this!",
      "This analysis is based off your Facebook messages. Facebook can use analysis like this to target political ads, puppy videos and gambling adverts!",
      "As we generate more data, we give companies and AIs information they can use to predict our reactions, to target us with messages designed to resonate with our personality type.",
      "In the right hands, data is a force for good, in the wrong hands it can be used at scale to manipulate the public opinion of entire nations.",
      "It's important that regular people understand how this works, and take care who has access to data about them. Let us know if you'd like more tools to manage your data footprint.",
    ],
    "noflip",
    ["facebook"],
    "personalitytraits",
    "notLive"
  ),
  new graphItem(
    "Needs and values",
    "Beyond broad personality traits, the text you write can reveal a lot about your values and your needs.",
    "placeholders/needsPreview.webp",
    graphCategories[2] + "/needs",
    [
      "Most people need a psychologist to find out what human needs they hold most strongly. Facebook just needs the thousands of words you've written on Facebook to advertise you what grabs your attention the most.",
      "This analysis is based off your messages and uses the IBM Watson API to infer your needs and values.",
      "It shows you how strongly each of the 12 needs and 5 values evalutated are detected in your messages. A score above 0.5 shows that you have a greater than average tendency, while above 0.75 is high.",
      "Your values are relevant to advertisers, including political advertisers and so are your needs which reveal which aspects of life you need fulfilled most!",
      "In addition to personality analysis, these are powerful insights for customer acquisition, customer care, targeted marketing, but also products that involve matching people e.g. dating apps.",
    ],
    "noflip",
    ["facebook"],
    "personalitytraits",
    "notLive"
  ),
  new graphItem(
    "Tweet Content",
    "What do you tweet about all day? From ranting to inspiring, explore your tweets as a network.",
    "placeholders/tweetcontentPreview.webp",
    graphCategories[2] + "/tweet-content",
    [
      "This network shows thousands of co-occurences from your search activity - the terms that appear together in your tweet content.",
      "So what are you like on Twitter? Is your network a complete and dense mess? Or can you find some conversation clusters such as rants adressed at airlines?",
      "Your tweets are rich of information about you: anything else you'd like to know from your tweets? Give us a suggestion in the feedback box!",
    ],
    "noflip",
    ["twitter"],
    "twittertweets",
    "notLive"
  ),
  new graphItem(
    "Youtube History",
    "Almost 5 billion videos are watched on Youtube every day. This is your Youtube universe. I suppose you could call it your Youniverse (sorry)! ",
    "placeholders/googlesearchPreview.webp",
    graphCategories[2] + "/youtube-history",
    [
      "This network shows up to 4,000 links between co-occuring words in the title of Youtube History items, if you uploaded 'My Activity/Youtube/My Activity.html'.",
      "Your Youtube History reflects your interests and daily activities, whether it's recipe tutorials, listening to music, or watching household items get crushed.",
      "Google knows you crazily well. Every video you watch on Youtube helps them build a profile on you and make their services ever more personalized.",
      "Ultimately, this is how you pay for Google: the company makes billions through its advertising network because they know exactly which ads to show you when.",
    ],
    "noflip",
    ["google"],
    "youtubesearch",
    "notLive"
  ),
  new graphItem(
    "Google Search",
    "3.5 billion search queries are made on Google every day, what were your top searches over the last years or months? ",
    "placeholders/googlesearchPreview.webp",
    graphCategories[2] + "/google-searches",
    [
      "This network shows thousands of co-occurences from your search activity - the terms that appear together in Google queries.",
      "Those queries reflect your daily activities and interests, whether it's reading the news, looking for your next holiday destination, or checking a recipe.",
      "Everything you spontaneously search on Google helps them build your profile and make their services more personalized.",
      "Ultimately, this is how you pay for Google: the company makes billions through its advertising network because they know exactly which ads to show you when.",
    ],
    "noflip",
    ["google"],
    "googlesearch",
    "notLive"
  ),
  new graphItem(
    "Music",
    "Your music tastes can reveal a lot about your personality and no company knows them better than Spotify.",
    "placeholders/musicPreview.webp",
    graphCategories[2] + "/music",
    [
      "Nothing says more about someone's soul than the music they really listen to!",
      "Using the Spotify API, you can see who your favorite artists are overall and recently, how indie your tastes are, as well as your favorite music genres.",
      "You might follow artists you wish you liked, but nothing is more revealing than who and what you actually spend time listening to.",
      "Do you recognize yourself in those tastes? Those preferences are part of who you are and can be used to profile you.",
    ],
    "flip",
    ["spotify"],
    "Music",
    "isLive"
  ),
];

export let targetingGraphList = [
  new graphItem(
    "Facebook Interests",
    "Facebook predicts your interests to target you better with ads. This makes them more money by encouraging you to buy more products.",
    "placeholders/interestsPreview.webp",
    graphCategories[3] + "/facebook-interests",
    [
      "Facebook has analysed your activity to infer interests, and uses them to target you with political messages, puppy videos and gambling adverts! Some will be inaccurate as AI systems often make mistakes!",
      "If your best friend listed all your interests, would they know as many? It's scary how much an AI can know about you.",
      "As AIs become more powerful, it's going to become more important for us humans to understand what those AIs know about us. That's one reason why we founded Magicflow.",
      "If you want to get involved and support our mission, have a chat with a founder. Maybe even become a Founding User!",
    ],
    "noflip",
    ["facebook"],
    "broadinterests",
    "notLive"
  ),
  new graphItem(
    "Facebook Ads",
    "Facebook targets you with ads constantly. Their real product isn't the app you use, it's your attention, to manipulate your behaviour for advertisers.",
    "placeholders/adsPreview.webp",
    graphCategories[3] + "/facebook-ads",
    [
      "Here's the last 60 days of ads you clicked. Facebook constantly targets you with ads - their real product isn't the app you use, it's your attention, and the ability to manipulate how you spend it - i.e. to get you to click an ad.",
      "It's worth being wary what you click on if you don't want to help Facebook manipulate you. When you react to posts, videos and comments, you teach them how to target you better.",
      "As these corporate AI systems become more powerful, it's going to become more important for us humans to understand what those AIs know about us. That's one reason why we founded Magicflow.",
      "If you want to get involved and support our mission, have a chat with a founder. Maybe even become a Founding User!",
    ],
    "noflip",
    ["facebook"],
    "adstable",
    "notLive"
  ),
  new graphItem(
    "Facebook Audiences",
    "Companies use Facebook to target you, including uploading lists of people's contact information to reach them directly on Facebook. Who's targeting you?",
    "placeholders/adlistsPreview.webp",
    graphCategories[3] + "/facebook-audiences",
    [
      "In 2019, Facebook had 6 million businesses using its platform for advertising. They use the platform's powerful marketing services to target users based on their personal data.",
      "Facebook lets businesses upload lists of contact information to target people directly; those are often obtained when you share your email address with them e.g. upon signing up to a service, or with their partners.",
      "Recognize all these companies? Remember giving them your details? If not, that's no surprise. We're kept in the dark about advertising partnerships between businesses, and are unprotected against data brokers selling our data to companies who then target you on Facebook!",
    ],
    "noflip",
    ["facebook"],
    "advertisinglists",
    "notLive"
  ),
  new graphItem(
    "Preferences",
    "Personality analysis goes beyond your traits, it can predict the movies you like and even whether you're more influenced by social media or family!",
    "placeholders/entertainmentPreview.webp",
    graphCategories[3] + "/preferences",
    [
      "Did you know that your personality can help companies infer what movies you like, or whether you are likely to start a business? Taken all together, those insights enable companies to profile you very precisely, even if they might sometimes get it wrong.",
      "Your messages can reveal your inclination for certain shopping preferences, such as what influences you to make purchases or what type of clothes you would rather buy.",
      "The entertainment and shopping preferences showed here were derived through the IBM Watson API from your personality traits using a machine learning model, themselves based on your Facebook messages.",
      "Based on your personality, this analysis tells you whether you are likely to like certain kind of movies, whether you read often, and whether you go to concerts; plenty of useful information for companies to target you better!",
      "Even without sharing your preferences online, modern data science techniques and good data can tell advertisers and companies a lot about you.",
      "Personality traits are also associated with activities you will undertake, such as volunteering, or going to the gym! Even if those models are not always accurate, your data when used in a clever way can be a good mirror of your personality.",
    ],
    "noflip",
    ["facebook"],
    "Shopping Preferences",
    "notLive"
  ),
  new graphItem(
    "Twitter Interests",
    "Twitter infers your interests from who you follow and the tweets you like, to target you better with ads and personalise your feed.",
    "placeholders/interestsPreview.webp",
    graphCategories[3] + "/twitter-interests",
    [
      "Twitter has analysed your activity to infer interests, and uses them to target you with political messages, puppy videos and gambling adverts! Some will always be inaccurate, as AI systems often make mistakes!",
      "If your best friend listed all your interests, would they know as many? It's scary how much an AI can know about you.",
      "As AIs become more powerful, it's going to become more important for us humans to understand what those AIs know about us. That's one reason why we founded Magicflow.",
      "If you want to get involved and support our mission, have a chat with a founder. Maybe even become a Founding User!",
    ],
    "flip",
    ["twitter"],
    "Twitter_Interests",
    "notLive"
  ),
  new graphItem(
    "Twitter Ads",
    "Discover how you are being targeted on Twitter and understand why the ads you see are unique to you!",
    "placeholders/twitteradsPreview.webp",
    graphCategories[3] + "/twitter-ads",
    [
      "Just like Facebook and other social media platforms, Twitter lets companies target you with ads based on the data they collect about you when on (and sometimes off!) Twitter.",
      "Here you can access and explore in details, all the criteria used to target you from location to keywords and similar accounts. Twitter associates you with topics and users to create lookalike audiences that help companies target you.",
      "We show you every single company that ever showed you an ad and which criteria and datapoints they used to find you and make you (or at least try to) click on their ad.",
    ],
    "noflip",
    ["twitter"],
    "twitterads",
    "notLive"
  ),
  new graphItem(
    "Twitter Audiences",
    "Companies use Twitter to target you, uploading lists of emails to target you directly and creating 'lookalike audiences'. Who targets you?",
    "placeholders/adlistsPreview.webp",
    graphCategories[3] + "/twitter-audiences",
    [
      "Businesses and politicians use the Twitter's powerful marketing services to target users based on their personal data.",
      "Twitter also lets businesses upload lists of contact information to target people directly; those are often obtained when you share your email address with them e.g. upon signing up to a service, or with their partners.",
      "Recognize all these companies? Remember giving them your details? If not, that's no surprise. We're kept in the dark about advertising partnerships between businesses, and are unprotected against data brokers selling our data to companies who then target you on Twitter!",
    ],
    "flip",
    ["twitter"],
    "Twitter_Audiences",
    "notLive"
  ),
  new graphItem(
    "LinkedIn Inferences",
    "LinkedIn uses your activity on the platform to make inferences about you, to help advertisers target you better.",
    "placeholders/linkedininferencesPreview.webp",
    graphCategories[3] + "/linkedin-inferences",
    [
      "LinkedIn uses your activity to make inferences about you, that help advertisers target you better - from age to employment status, to interests and characteristics like leadership.",
      "We show you what inferences LinkedIn made about you based on your likes, clicks in the job search section, or skills.",
      "This lets advertisers on the platform select you and show you ads they think you'll be likely to click on.",
    ],
    "noflip",
    ["linkedin"],
    "linkedininferences",
    "notLive"
  ),
];

export let socialisingGraphList = [
  new graphItem(
    "Top Friends",
    "See who you have messaged the most since you started Facebook, to get a weighted picture of your whole social graph.",
    "placeholders/mostMessaged.webp",
    graphCategories[4] + "/top-friends",
    [
      "Our social lives change lots as we get older, whether it's changing jobs or leaving school. Have you lost touch with someone you used to message alllll the time?",
      "Facebook can learn a lot from how much you message people on Facebook. The big circles aren't necessarily your best friends, but they're up there!",
    ],
    "noflip",
    ["facebook"],
    "topfriends",
    "notLive"
  ),
  new graphItem(
    "Facebook Reactions",
    "After thousands of likes and reactions, who do you react to the most? What gets you the most engaged?",
    "placeholders/reactionsPreview.webp",
    graphCategories[4] + "/fb-reactions",
    [
      "Who is your funniest friend on social media? See which friends and pages you've reacted to the most since you signed up.",
      "A like or reaction to a post, picture or comment takes a second and seems insignificant. But for Facebook, it's a way to get to know you.",
      "From a simple reaction, Facebook discovers how different content posted by certain people or pages make you feel.",
      "Besides the reaction-type, the simple fact that you react to some content and not other allows Facebook to feed you more specific content that maximises your engagement.",
    ],
    "noflip",
    ["facebook"],
    "FBreactions",
    "notLive"
  ),
  new graphItem(
    "Messages over time",
    "See how who you message the most has changed over time since you started Facebook. Can you spot big life or relationship changes?",
    "placeholders/friendsBarRace.webp",
    graphCategories[4] + "/fb-friends-time",
    [
      "Remember your first ever Facebook friends? How much does this graph represent your real social life?",
      "Almost 35% of the world's population is on Facebook. A lot of your real life relationships translate into an online relationship on Facebook.",
      "Besides knowing a lot about your social network, Facebook also knows how your social life evolve. They know what stage in life you're in and when you are making new friends.",
    ],
    "noflip",
    ["facebook"],
    "messagebarchartrace",
    "notLive"
  ),
  // new graphItem(
  //   "Facebook Friends",
  //   "You've made a lot of friends since you started using Facebook. Spikes are often correlated with moving cities, schools or jobs.",
  //   "placeholders/fbfriendsPreview.webp",
  //   graphCategories[4] + "/fb-friends",
  //   [
  //     "Remember your first ever Facebook friends? How much does this trend represent your real social life?",
  //     "Almost 35% of the world's population is on Facebook. Most likely, a lot of your real life relationships translate into an online relationship on Facebook.",
  //     "Besides knowing a great part of your social network, Facebook also knows about how your social life evolves, for instance when you are making new friends and what stage in your life this might correspond to.",
  //   ]
  // ),
  new graphItem(
    "Conversations",
    "Which friends are you most positive with, and who do you go to and complain to? A sentiment analysis of your conversations!",
    "placeholders/conversationsentimentPreview.webp",
    graphCategories[4] + "/conversations",
    [
      "See which friends you're most positive, and who you moan about life to. Are you always positive, or a sarcastic curmudgeon with your best mates?",
      "The first graphs shows you the polarity, or sentiment, detected in the messages sent or received by your best friends (those you message the most). A score of 50 is neutral, towards 0 is negative, and towards 100 is positive.",
      "The next ones show you the most positive and negative conversations you had. You can explore each relationship in more details to see how sentiment evolved between you and your friends.",
      "The sentiment is computed using the Valence Aware Dictionary for Sentiment Reasoning (same as your mood, see Your Life category) and uses words, context, punctuation, capitalization and smileys to detect polarity in messages.",
    ],
    "noflip",
    ["facebook"],
    "facebooksentiment",
    "notLive"
  ),
  new graphItem(
    "Tie Strength",
    "Which friends are you most positive with, and who do you go to and complain to? A sentiment analysis of your conversations!",
    "placeholders/conversationsentimentPreview.webp",
    graphCategories[4] + "/tie-strength",
    [
      "New tech is often met with questions about its impact on our psychological well-being. A predictive model can be used to identify the strong and weak ties of your Facebook friendships!",
      "Your social interaction on Facebook can also be used to observe changes in your psychological well being.",
      "To give you more insight into your Facebook friendships, the Watson tone analyzer analyzes the emotion and tone of your messages—anger, fear, joy, sadness, analytical, confident, tentative, and neutral.",
      "Explore your friendships by navigating the graphs! The size of the nodes correspond to the predicted strengths of each Facebook friendship. You’ll also see clusters and colors that correspond to the most prominent tone in your messages.",
      "How strong are your friendships on Facebook? How does this affect your mental wellbeing?",
    ],
    "noflip",
    ["facebook"],
    "facebooksentiment"
  ),
  new graphItem(
    "Twitter Interactions",
    "You interact with thousands of people on Twitter through tweets, retweets, and tags: what would a map of those interactions look like?",
    "placeholders/twitterinteractionsPreview.webp",
    graphCategories[4] + "/twitter-connections",
    [
      "This graph shows you who you interact with on Twitter - meaning the accounts that are referenced in tweets or retweets you made - and contextualises those interactions by mapping your network i.e. which users you interact with together.",
      "The size of the nodes corresponds to the number of interactions with the user and shows who you reference most: but beyond this, you can also see how your interactions are structured.",
      "Can you identify clusters? Or a core of people you always interact with? What do they represent? Twitter identifies those over time and can use its knowledge of your network to reinforce interactions between the same people by exposing you to their content.",
    ],
    "noflip",
    ["twitter"],
    "twitterinteractions",
    "notLive"
  ),
  new graphItem(
    "Instagram Following",
    "You follow and are followed by friends and strangers, Facebook uses your Instagram social graph to socially target ads.",
    "placeholders/fbfriendsPreview.webp",
    graphCategories[4] + "/insta-following",
    [
      "This graph shows you how your Instagram followers and following has grown over time.",
      "Can you identify any meaningful points in time where your counts went up? Maybe when you started at a new job or university.",
    ],
    "noflip",
    ["instagram"],
    "Instagram_Following",
    "notLive"
  ),
  new graphItem(
    "Instagram Likes",
    "Likes are the currency of Instagram. They shape whose content shows up in your feed. Who have you made rich?",
    "placeholders/reactionsPreview.webp",
    graphCategories[4] + "/insta-likes",
    [
      "This graph shows you whose posts and comments on Instagram you've liked the most over time.",
      "Is it friends of yours who you've showered in love, or hilarious meme accounts? Instagram doesn't care, you're just an ad-viewer to them.",
    ],
    "noflip",
    ["instagram"],
    "Instagram_Likes",
    "notLive"
  ),
  new graphItem(
    "Professional Connections",
    "Explore your professional network, the companies and jobs most represented and the connection between your contacts based on common employers. ",
    "placeholders/professionalPreview.webp",
    graphCategories[4] + "/linkedin-connections",
    [
      "LinkedIn knows your professional life in detail! Based on your network, they can understand your position in the job market and generally in the professional world.",
      "Explore your network by navigating the graph: you'll see small clusters that show which of your connections work at the same company. How diverse are your connections and how concentrated is your professional network?",
    ],
    "noflip",
    ["linkedin"],
    "linkedinconnections",
    "notLive"
  ),
];

export let compromisingGraphList = [
  new graphItem(
    "Cybersecurity Summary",
    "In the wrong hands, data about you can be used to socially engineer or impersonate you.",
    "placeholders/cyberPreview.webp",
    graphCategories[1] + "/summary",
    [
      "In the wrong hands, data is a security threat, used to manipulate you, impersonate you, or guess your security questions. Hover over each stat to understand how it could be dangerous.",
      "Putting things on Facebook used to seem harmless. But aren't your date of birth and your mother's maiden name security questions?",
      "Each stat is placed on a gauge to help you compare with what we consider to be 'high' amounts of information given away.",
      "We're working on digging deeper to demonstrate exactly how this data can be used to compromise you, watch this space.",
      "And let us know any feedback/requests you have, or ways we can make this clearer or more useful!",
    ],
    "noflip",
    ["facebook"],
    "Cybersecurity Summary",
    "notLive"
  ),
  new graphItem(
    "Breached Accounts",
    "Magicflow automatically monitors emails associated with your accounts for breaches, so you can keep yourself protected.",
    "placeholders/breachesPreview.webp",
    graphCategories[1] + "/breach-monitoring",
    [
      "Breaches happen rarely, but they can lead to identity theft and further damage down the line.",
      "If there are any breaches here you don't recognise, make sure you change your passwords on those accounts and are aware of what information has leaked about you.",
      "Info that gets leaked about you can be used by attackers to make credible phishing emails, so stay alert for unusual emails!",
    ],
    "noflip",
    ["facebook", "twitter", "instagram", "google", "linkedin"],
    "breaches",
    "live"
  ),
  new graphItem(
    "Risky Communication",
    "Since you've been on Facebook, you've probably left thousands of comments. How risky have you been?",
    "placeholders/datataggeroverviewPreview.webp",
    graphCategories[1] + "/facebook-risk-overview",
    [
      "Get an overview of the sensitive content found in your comments including amount, risk levels and top categories.",
      "Have a look at the total amount of terms flagged, which you can explore in the Sensitive Comments tab, as well as your average risk score and weighted average risk score (the average risk level that accounts for the number of terms in each risk level).",
      "The bottom chart shows you the risk categories that were flagged the most in your comments. You can see how many terms were flagged in each category and their associated risk level in the Risk Categories tab.",
    ],
    "noflip",
    ["facebook"],
    "datataggeroverview",
    "notLive"
  ),
  new graphItem(
    "Sensitive Comments",
    "See which of the public comments you've left we flagged as potentially sensitive, and clean them up.",
    "placeholders/datataggerdeepdivePreview.webp",
    graphCategories[1] + "/facebook-risk",
    [
      "With years of public data shared on Facebook, it's hard to keep track of what might affect you. We help you by showing terms we consider potentially sensitive found in your comments.",
      "Explore the terms our system flagged as potentially sensitive. You can sort the table by risk level or amount of times the term was found. Filter the table by category to directly focus on what you care about, or search specific terms to make sure they don't appear.",
      "Based on what you find, you might want to delete your comments. For now, we allow you to delete all your comments and are working on a more fine-grained removal of comments to only exclude the sensitive content.",
    ],
    "noflip",
    ["facebook"],
    "datataggerdeepdive",
    "notLive"
  ),
  new graphItem(
    "Sensitive Tweets",
    "See which of the public tweets you've made get flagged as potentially sensitive, and clean them up.",
    "placeholders/datataggerdeepdivePreview.webp",
    graphCategories[1] + "/twitter-risk",
    [
      "With years of public tweets shared on Twitter, it's hard to keep track of what might affect you. We help you by showing terms we consider potentially sensitive.",
      "Explore the terms our system flagged as potentially sensitive. You can sort the table by risk level or amount of times the term was found. Filter the table by category to directly focus on what you care about, or search specific terms to make sure they don't appear.",
      "Based on what you find, you may want to delete your tweet. For now, we allow you to delete all your comments and are working on a more fine-grained removal of tweets to only delete the sensitive tweet.",
    ],
    "noflip",
    ["twitter"],
    "twittertagger",
    "isLive"
  ),
];

export let homeContent = {
  tracking: {
    home: {
      webTitle: "Magicflow • Tracking",
      title: "Tracking",
      straplines: [
        "Nearly every website you visit is tracking your behaviour - your clicks and scrolls. Companies collect all sorts of data, and data brokers try to connect it all together so they can track and target you across different sites.",
      ],
    },
    icon: "eye",
    graphList: trackingGraphList,
  },
  profiling: {
    home: {
      webTitle: "Magicflow • Profiling",
      title: "Profiling",
      straplines: [
        "It would be strange if somebody you didn't know, knew more about who you are than anyone in the world. Who do you think knows you best out of everyone in the world?",
        "Is it your best friend? Your mum and dad? If you're lucky, perhaps that's true, but maybe the data you've given Facebook allows their algorithms to know you better than anybody. Do you really trust them that much?",
      ],
    },
    icon: "user",
    graphList: profilingGraphList,
  },
  targeting: {
    home: {
      webTitle: "Magicflow • Targeting",
      title: "Targeting",
      straplines: [
        "Your data is collected for a reason - to target you. By collecting details about your life, they put together a profile with which they decide what you see, and what ads you're most likely to click.",
        "The devices you use, how often you use them and the places you go all provide clues into income, work habits, education levels, and even loneliness.",
      ],
    },
    icon: "bullseye",
    graphList: targetingGraphList,
  },
  socialising: {
    home: {
      webTitle: "Magicflow • Socialising",
      title: "Socialising",
      straplines: [
        'Every time you message, tag or interact with a friend, it strengthens a "social link" between you and them.',
        "Facebook probably knows more than you realise about your network and social life. It's how they predict what you talked about, and show you ads that make you think they're listening 👂.",
      ],
    },
    icon: "users",
    graphList: socialisingGraphList,
  },
  compromising: {
    home: {
      webTitle: "Magicflow • Security",
      title: "Your Security Hub",
      straplines: [
        "The data companies collect clearly compromises your privacy. But in the wrong hands, it can also compromise your security.",
        "We've all been leaving traces online for a long time, traces that might allow somebody to know dangerous things about us. Some of us were children, or teenagers, when we started commenting on Facebook, and might regret immature language in a public forum.",
        "Hopefully this section makes you aware how your data can be used against you, and lets you identify what data you'd like to clear up!",
      ],
    },
    icon: "exclamation-triangle",
    graphList: compromisingGraphList,
  },
};
